export default class LegalRepresentative {
    constructor({
        representativeId = 0, 
        firstName = null, 
        lastName = null, 
        mothersMaidenName = null, 
        dateOfBirth = null, 
        birthState = null, 
        nationality = null, 
        gender = null, 
        maritalStatus = null, 
        maritalRegime = null, 
        rfc = null, 
        curp = null, 
        homePhone = null, 
        officePhone = null, 
        cellPhone = null, 
        email = null, 
        identificationType = null, 
        identificationNumber = null,
    } = {}) {
        this.representativeId = representativeId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.mothersMaidenName = mothersMaidenName;
        this.dateOfBirth = dateOfBirth;
        this.birthState = birthState;
        this.nationality = nationality;
        this.gender = gender;
        this.maritalStatus = maritalStatus;
        this.maritalRegime = maritalRegime;
        this.rfc = rfc;
        this.curp = curp;
        this.homePhone = homePhone;
        this.officePhone = officePhone;
        this.cellPhone = cellPhone;
        this.email = email;
        this.identificationType = identificationType;
        this.identificationNumber = identificationNumber;
    }
}
    
