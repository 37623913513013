<template>
  <Form ref="form" @submit="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">{{ $getLocaleMessage('buttons', 'updating') }} documentación digitalizada</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Información</h3>
        </div>
        <div class="row card-body">
          <div class="form-group col-sm-12">
            <label for="branchOfficeId">Matriz y/o Sucursales:</label>
            <BranchOfficesSelect v-model="selectedBranchOfficeId" :name="'branchOfficeId'"
              :options="activeBranchOfficesOptions" :isDisabled="true"></BranchOfficesSelect>
          </div>

          <div class="form-group col-sm-12">
            <label for="ProspectFullname">Prospecto:</label>
            <Field v-model="getProspectusFullname" type="text" class="form-control" name="ProspectFullname" disabled />
          </div>

          <div class="form-group col-sm-12">
            <label for="recordTypeId">Tipos de expendiente:</label>
            <RecordTypesSelect v-model="selectedRecordTypeId" :name="'recordTypeId'" :options="activeRecordsOptions"
              :isDisabled="true"></RecordTypesSelect>
          </div>
          <div class="form-group col-sm-6">
            <label for="referenceNumber">Folio de Expediente:</label>
            <Field v-model="selectedFinancialRecord.referenceNumber" type="text" class="form-control"
              name="referenceNumber" disabled />
          </div>
          <div class="form-group col-sm-6">
            <label for="name">Nombre de Expediente:</label>
            <Field v-model="selectedFinancialRecord.name" type="text" class="form-control" name="name" disabled />
          </div>
          <div class="form-group col-sm-12">
            <label for="statusId">Estatus:</label>
            <StatusSelect v-model="selectedStatusId" :name="'statusId'" :options="statusOptions"></StatusSelect>
          </div>
        </div>
      </div>

      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Documentación</h3>
        </div>
        <div class="card-body">
          <div class="row" v-if="getActiveChecklists">
            <div class="info-box col-12 col-sm-12" v-for="(element, idx) in getActiveChecklists" :key="idx">
              <div class="form-group col-sm-6" v-if="element.file">
                <label>Descargar archivo:</label>
                <p><a :href="element.file.filePath" target="_blank">{{ element.file.name
                    }}</a></p>
              </div>
              <div class="form-group" :class="[element.file ? 'col-sm-6' : 'col-sm-12']">
                <label :for="'exampleInputFile'+idx">{{ element.document.documentName }}{{ (element.remarks) ? ` (${element.remarks})` : '' }}:</label>
                <div class="input-group">
                  <label>
                    <input type="file" ref="fileInputs" class="" :id="'exampleInputFile' + idx"
                      accept=".jpg, .jpeg, .png, .gif, .pdf" @change="handleFileChange(idx)" />
                    <span class="file-custom"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        {{ $getLocaleMessage('buttons', 'cancel') }}
      </button>
      <PrimaryButton class="btn btn-primary" type="submit">
        {{ $getLocaleMessage('buttons', 'update') }}
      </PrimaryButton>
    </div>
  </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from "pinia";
import { financialRecordsStore } from "@/pinia/financialRecords.module";
import { useRecordTypesStore } from "@/pinia/recordTypes.module";
import { useBranchOfficesStore } from "@/pinia/branchOffices.module";
import { useIndividualProspectsStore } from "@/pinia/Prospects/individual.module";
import { sharedStore } from "@/pinia/sharedStore.module";
import { useStatusStore } from '@/pinia/status.module';
import { checklistsStore } from '@/pinia/checklists.module';

import BranchOfficesSelect from "@/Components/Selects/SelectField.vue";
import RecordTypesSelect from "@/Components/Selects/SelectField.vue";
import StatusSelect from "@/Components/Selects/SelectField.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";

import Prospectus from '@/Models/Prospects';
import FinancialRecord from '@/Models/FinancialRecord';
import RecordsProspects from '@/Models/RecordsProspects';
import RecordFileForm from '@/Models/RecordFileForm';

export default {
  components: {
    PrimaryButton,
    StatusSelect,
    BranchOfficesSelect,
    RecordTypesSelect,
  },
  mixins: [],
  data: () => ({
    recordProspectus: new RecordsProspects({}),
    recordFileForm: new RecordFileForm({}),
    selectedBranchOfficeId: null,
    selectedRecordTypeId: null,
    selectedStatusId: null,
    selectedFinancialRecord: new FinancialRecord({}),
    currentProspectus: null,
    currentFinancialRecord: null,
    fileInputs: [],
    files: {},
    filesId: [],
    recodProspectusFiles: null,
    checklistDocuments: null,
    hasFiles: false,
  }),
  emits: ['selected-branch-office', 'change-modal-content'],
  props: {
    selectedItem: {
      type: Object,
      default: () => new RecordsProspects({}),
    },
    prospectus: {
      type: Prospectus,
      default: () => new Prospectus({}),
    },
  },
  computed: {
    ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
    ...mapState(financialRecordsStore, ['getFinancialRecords', 'getRecordProspectus', 'getSelectedFinancialRecord', 'getFinancialRecordById']),
    ...mapState(useIndividualProspectsStore, ['getAllProspects', 'getSelectedProspectus', 'getProspectBySelected']),
    ...mapState(sharedStore, ['getProspectusById', 'getProspectus', 'getProspectusFullname']),
    ...mapState(useRecordTypesStore, ['getRecordTypes', 'getSelectedRecordType']),
    ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
    ...mapState(checklistsStore, ['getActiveChecklists']),
    activeBranchOfficesOptions() {
      return (this.getActiveBranchOffices) ? this.branchOfficeOptions(this.getActiveBranchOffices) : [];
    },
    activeRecordsOptions() {
      return (this.getRecordTypes) ? helper.mapOptions(this.getRecordTypes) : [];
    },
    statusOptions() {
      return (this.getAllStatusByType(4)) ? helper.mapOptions(this.getAllStatusByType(4)) : [];
    },
    selectedRecordType() {
      return this.getSelectedRecordType.id;
    },
    selectedStatus() {
      return this.getSelectedStatus.id;
    },
    filesLength() {
      return (this.getRecordProspectus?.files) ? this.getRecordProspectus.files.length : 0;
    },
    checklistDocumentsLength() {
      return (this.checklistDocuments) ? this.checklistDocuments.length : 0;
    }
  },
  methods: {
    ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
    ...mapActions(financialRecordsStore, ['getDocumentationByRecordId', 'updateRecordProspectus', 'fetchRecordProspectusById', 'setSelectedFinancialRecord']),
    ...mapActions(sharedStore, ['fetchProspectus', 'setSelectedProspectus']),
    ...mapActions(useRecordTypesStore,['setSelectedRecordType']),
    ...mapActions(useStatusStore, ['setSelectedStatus']),
    ...mapActions(checklistsStore, ['fetchActiveChecklistsWithFilesByRecordId']),
    onSubmit() {
      const formData = new FormData();
      // Add files to the FormData
      formData.append('recordFileFormDto.RecordProspectusDto.Id', this.selectedItem.id);
      formData.append('recordFileFormDto.RecordProspectusDto.recordId', this.selectedFinancialRecord.id);
      formData.append('recordFileFormDto.RecordProspectusDto.prospectusId', this.selectedItem.prospectusId);
      formData.append('recordFileFormDto.RecordProspectusDto.branchOfficeId', this.selectedBranchOfficeId);
      formData.append('recordFileFormDto.RecordProspectusDto.statusId', this.selectedStatusId);

      Object.values(this.filesId).forEach((item, index) => {
        formData.append(`recordFileFormDto.FilesId[${index}]`, item);
      });

      Object.values(this.files).forEach((file, index) => {
        formData.append(`recordFileFormDto.Files`, file);
      });

      // for(var pair of formData.entries()) {
      //   console.log(pair[0]+ ', '+ pair[1]); 
      // }

      this.updateRecordProspectus(formData);
      this.$refs.form.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal", true);
    },
    handleFileChange(index) {
      const fileId = this.getActiveChecklists[index].documentId; // Get the Id from Documents table
      const fileInput = this.$refs.fileInputs[index];
      if (fileInput.files.length > 0) {
        // this.$set(this.files, index, fileInput.files[0]);
        this.filesId.push(fileId);
        this.files[index] = fileInput.files[0];
      } else {
        // this.$delete(this.files, index);
        delete this.files[index];
      }
    },
    branchOfficeOptions(data) {
        return helper.mapOptions(data);
    },
    setNewStatus(statusId) {
      this.recordProspectus.statusId = statusId;
      this.setSelectedStatus(statusId);
    },
  },
  watch: {
    'getProspectus': {
      handler(newItems) {
        if (newItems.length > 0) {
          this.currentProspectus = this.getProspectusById(this.selectedItem.prospectusId);
          if (this.currentProspectus) {
            this.setSelectedProspectus(this.currentProspectus.prospectusId);
          }
        }
      },
      immediate: true,
    },
    'getActiveChecklists': {
      handler(newValue) {
        if (newValue) {
          this.checklistDocuments = newValue;
        }
      }
    },
    'getRecordProspectus.files' : {
      handler(newValue) {
        if (newValue) {
          this.hasFiles = newValue.length > 0;
          this.recodProspectusFiles = newValue;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.selectedItem.id) {
        this.selectedBranchOfficeId = (this.selectedItem?.branchOfficeId ? this.selectedItem.branchOfficeId : null);
        this.selectedRecordTypeId = (this.selectedItem?.financialRecord?.recordTypeId ? this.selectedItem.financialRecord.recordTypeId : null);
        this.selectedFinancialRecord = (this.selectedItem?.financialRecord ? this.selectedItem.financialRecord : null);
        this.selectedStatusId = (this.selectedItem?.statusId ? this.selectedItem.statusId : null);
      // Fetch RecordProspectus by Id with all its relations
        this.fetchRecordProspectusById(this.selectedItem.id);
        this.fetchActiveChecklistsWithFilesByRecordId(this.selectedFinancialRecord.id);
    }
  },
  mounted() {
  },
};
</script>