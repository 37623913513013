import { createRouter, createWebHistory } from 'vue-router';

import Home from "@/Pages/Home.vue";
import Dashboard from '@/Layouts/DashboardLayout.vue';
import IndexCompanies from "@/Pages/Companies/Index.vue";
import IndexBranchOffices from "@/Pages/BranchOffices/Index.vue";
// import Login from "@/Pages/Users/Login.vue";
import Login from '@/Layouts/LoginLayout.vue';
import Profiles from "@/Pages/Users/Index.vue";
import IndexRoles from "@/Pages/Roles/Index.vue";
import IndexPermissions from '@/Pages/Permissions/Index.vue';
import FinancialRecords from '@/Pages/FinancialRecords/Index.vue';
import NotFoundPage from '@/Pages/NotFoundPage.vue'; // Importa la vista de la página 404
import IndexProducts from '@/Pages/Products/Index.vue';
import IndexHolidays from '@/Pages/Holidays/Index.vue';
import IndexTaxes from '@/Pages/Taxes/Index.vue';
import IndexFinancialFunders from '@/Pages/FinancialFunders/Index.vue';
import IndexBanks from '@/Pages/Banks/Index.vue';
import Tests from '@/Pages/Tests/Index.vue';

// Secciones de Proceso del Colocación de Crédito:
import IndexProspects from '@/Pages/Prospects/Index.vue';
import IndexQuotations from '@/Pages/Quotations/Index.vue';
import IndexLoanApplications from '@/Pages/LoanApplications/Index.vue';
import IndexCreditDocumentation from '@/Pages/Documents/Index.vue';
import IndexCreditScores from '@/Pages/CreditScores/Index.vue';
import IdexClients from '@/Pages/Clients/Index.vue';
import store from '@/store';
import { useAuthStore } from '@/pinia/auth.module';

const routes = [{
        path: "/",
        name: "home",
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "/empresas",
                name: "companies",
                component: IndexCompanies,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/sucursales",
                name: "branchOffices",
                component: IndexBranchOffices,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/productos",
                name: "products",
                component: IndexProducts,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/dias-feriados",
                name: "holidays",
                component: IndexHolidays,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/impuestos",
                name: "taxes",
                component: IndexTaxes,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/fondeadoras",
                name: "financialFunders",
                component: IndexFinancialFunders,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/bancos",
                name: "banks",
                component: IndexBanks,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/usuarios",
                name: "users",
                component: Profiles,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario']
                },
            },
            {
                path: "/roles",
                name: "roles",
                component: IndexRoles,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario']
                },
            },
            {
                path: "/asignaciones",
                name: "assignments",
                component: IndexPermissions,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario']
                },
            },
            {
                path: "/financial-records",
                name: "financialRecords",
                component: FinancialRecords,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador',]
                },
            },
            {
                path: "/prospectos",
                name: "prospects",
                component: IndexProspects,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador', 'Ejecutivo']
                }
            },
            {
                path: "/cotizaciones",
                name: "quotations",
                component: IndexQuotations,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador', 'Ejecutivo']
                }
            },
            {
                path: "/solicitudes",
                name: "loanApplications",
                component: IndexLoanApplications,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador', 'Ejecutivo']
                }
            },
            {
                path: "/documentacion-crediticia",
                name: "credit-documentation",
                component: IndexCreditDocumentation,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador', 'Ejecutivo']
                }
            },
            {
                path: "/calificaciones",
                name: "credit-scores",
                component: IndexCreditScores,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador', 'Ejecutivo']
                }
            },
            {
                path: "/clientes",
                name: "clients",
                component: IdexClients,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario', 'Administrador', 'Ejecutivo']
                }
            },
            {
                path: "/testing",
                name: "testing",
                component: Tests,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['Programador', 'Super Usuario']
                }
            },

        ],
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: "/forbidden",
        name: "forbidden",
        component: NotFoundPage,
        meta: {
            requiresAuth: false,
        }
    },
    // Ruta 404 - Página no encontrada
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFoundPage,
    },
];

const router = new createRouter({
    history: createWebHistory(),
    routes
});

// Middleware para verificar la autenticación
router.beforeEach((to, from, next) => {

    const authStore = useAuthStore();
    let isAuthenticated = authStore.isAuthenticated;
    const userRoles = authStore.user?.roles || [];
    const requiredRoles = to.meta.allowedRoles;
    // console.log('Current Route:', to.path);
    // console.log('User Roles:', userRoles);
    // console.log('Required Roles:', requiredRoles);

    // Si el usuario está autenticado y está tratando de acceder a la página de inicio de sesión,
    // redirigimos al usuario a la página del panel de control.
    if (isAuthenticated && to.path === '/login') {
        next({ name: 'home' });
    } else if (to.meta.requiresAuth && !isAuthenticated) {
        // Si el usuario no está autenticado y la ruta requiere autenticación,
        // redirigimos al usuario a la página de inicio de sesión.
        next({ name: 'login' });
    } else if (requiredRoles !== undefined && requiredRoles && !checkRoles(requiredRoles, userRoles)) {
        // Si el usuario no tiene los roles requeridos para acceder a la ruta,
        // TODO: Make a Forbidden page
        // redirigimos al usuario a la página de Not Found
        next({ name: 'forbidden' });
        return;
    } else {
        // Si el usuario está autenticado o la ruta no requiere autenticación,
        // continuamos con la navegación.
        next();
    }
});

function checkRoles(requiredRoles, userRoles) {
    // Convertir userRoles a un array de strings
    const userRoleNames = userRoles.map(role => role.name);

    // Verifica si el usuario tiene al menos uno de los roles requeridos:
    return requiredRoles.some(role => userRoleNames.includes(role));

    // For debugging purposes:
    // const result = requiredRoles.some(role => {
        //     console.log('Role:', role);
    //     const hasRole = userRoleNames.includes(role);
    //     return hasRole;
    // });

    // return result;

}

export default router;