export default class Condition {
    constructor({
        id = 0,
        companyId = 0,
        name = '',
        createdAt = new Date(),
        updatedAt = new Date()
    } = {}) {
        this.id = id;
        this.companyId = companyId;
        this.name = name;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}