import { defineStore } from 'pinia';
import { getLocaleMessage } from '@/services/MessagesService';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import router from '@/routes/index';
import UserAuth from '../Models/UserAuth';

import * as service from '@/services';

const $toast = useToast();

export const useAuthStore = defineStore('auth', {
    persist: true,
    state: () => {
        return {
            isAuthenticated: null,
            user: new UserAuth(),
            message: '',
        }
    },
    getters: {
        // isAuthenticated: (state) => state.isAuthenticated,
        // message: (state) => state.message,
        getUser: (state) => state.user,
        getCompanyIdFromLoggedUser: state => state.user.companyId
    },
    actions: {
        async fetchLogin(userData) {
            const loadingStore = useLoadingStore();

            try {
                loadingStore.loading = true;
                // Hacer una solicitud a la API para realizar la autenticación
                const { data } = await service.profilesApi.post('/api/users/login', userData);

                // Verificar la propiedad isSuccess en la respuesta de la API
                loadingStore.loading = false;
                if (data.isSuccess) {
                    this.isAuthenticated = data.isSuccess;

                    const { data: user } = await service.profilesApi.get(`/api/users/GetByEmail/${userData.email}`);
                    this.user = new UserAuth(user);
                    // redirigir a la página de inicio
                    router.push({ name: 'home' });
                    return;
                } else {
                    // Si la autenticación falla, establece isAuthenticated en false
                    this.isAuthenticated = false;
                }

                // Actualiza el mensaje en el estado basado en la respuesta de la API
                $toast.error(data.message);
            } catch (error) {
               loadingStore.loading = false;

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
               loadingStore.loading = false;
            }
        },
        logout() {
            // Aquí puedes realizar la lógica de cierre de sesión, si es necesario
            this.isAuthenticated = false;
            this.user = {};
            router.push({ name: 'login' });
        }
    },
});