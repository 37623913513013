<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
        </ul>
    </nav>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <a href="index3.html" class="brand-link">
            <span class="brand-text font-weight-light">Sistema de Control</span>
        </a>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="info">
                    <a href="#" class="d-block"> KVF GROUP</a>
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item menu-close">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Proceso de Colocación de Crédito
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'prospects' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Prospectos</p>
                                </router-link>
                            </li>
                        </ul>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'quotations' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Cotizaciones</p>
                                </router-link>
                            </li>
                        </ul>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'loanApplications' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Solicitudes</p>
                                </router-link>
                            </li>
                        </ul>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'credit-documentation' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Subir Documentación</p>
                                </router-link>
                            </li>
                        </ul>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'credit-scores' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Calificaciones</p>
                                </router-link>
                            </li>
                        </ul>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'clients' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Clientes</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item menu-open">
                        <a href="#" class="nav-link active">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Configuraciones
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'companies' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Empresas</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'branchOffices' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Matriz y/o Sucursales</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'products' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Productos</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'financialRecords' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Documentación</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'holidays' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Días Feriados</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'taxes' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Impuestos</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'financialFunders' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Fondeadoras</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'banks' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Bancos</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item menu-close">

                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Perfiles
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link :to="{ name: 'users' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Usuarios</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'roles' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Roles</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'assignments' }" active-class="active" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Asignación de permisos</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a @click.prevent="logout()" href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Salir
                            </p>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
import { storeToRefs, mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/pinia/auth.module';

export default {
    methods: { ...mapActions(useAuthStore, ['logout']) },
    mounted() {
        // This block of code it is important for the correct operation of the menu menu
        // Do not delete this code
        let _this = this;

        let treeView;
        $(window).on('load.lte.treeview', function (e) {
            treeView = e;
        });

        var readyStateCheckInterval = setInterval(function () {
            if (document.readyState === "complete") {
                clearInterval(readyStateCheckInterval);

                if (treeView == undefined) {
                    _this.$treeViewInitOnce();
                }
            }
        }, 10);
    },
};

</script>

<style scoped></style>