<template>
<Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'update') }} documento del expediente <strong>{{ getActiveFinancialRecord.name }}</strong>
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="documentId">Documentos:</label>
                    <DocumentsSelect v-model="record.documentId" :name="'documentId'" :options="documentOptions"></DocumentsSelect>
                </div>
                <div class="form-group">
                    <label for="remarks">Observaciones:</label>
                    <Field as textarea v-model="record.remarks" type="textarea" class="form-control" name="remarks" placeholder="Observaciones"/>
                </div>
                <div class="form-group">
                    <label for="conditionId">Condiciones:</label>
                    <ConditionsSelect v-model="record.conditionId" :name="'conditionId'" :options="conditionOptions"></ConditionsSelect>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="record.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { checklistsStore } from '@/pinia/checklists.module';
import { financialRecordsStore } from '@/pinia/financialRecords.module';
import { documentsStore } from '@/pinia/documents.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from "@/Components/PrimaryButton.vue";
import DocumentsSelect from '@/Components/Selects/SelectField.vue';
import ConditionsSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';

import Checklist from '@/Models/RecordDocumentCondition';

export default {
    components: {
        PrimaryButton,
        DocumentsSelect,
        ConditionsSelect,
        StatusSelect,
    },
    data() {
        return {
            checklist: new Checklist(),
        };
    },
    props: {
        selectedRecord: {
            type: Object,
            default: () => {}
        }
    },
    emits: ['close-modal'],
    computed: {
        ...mapState(checklistsStore, ['getConditions', 'getChecklist']),
        ...mapState(financialRecordsStore, ['getActiveFinancialRecord']),
        ...mapState(documentsStore, ['getActiveDocuments']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        record() {
            this.checklist = this.selectedRecord;
            return this.checklist;
        },
        documentOptions() {
            return helper.mapOptions(this.getActiveDocuments, 'id', 'documentName');
        },
        conditionOptions(){
            return helper.mapOptions(this.getConditions);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(5));
        },
    },
    methods: {
        ...mapActions(checklistsStore, ['updateChecklist', 'setSelectedChecklist']),
        onSubmit() {
            this.updateChecklist(this.checklist);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
    },
    mounted() {
        this.setSelectedChecklist(this.selectedRecord.id);
    }
};
</script>