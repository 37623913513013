import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useAuthStore } from '@/pinia/auth.module';
import BranchOffice from '@/Models/BranchOffice';

import * as service from '@/services';

const $toast = useToast();

export const useBranchOfficesStore = defineStore('branchOffices', {
    persist: false,
    state: () => {
        return {
            branchOffices: [],
            branchOffice: new BranchOffice(),

            activeBranchOffices: [],
            activeBranchOffice: new BranchOffice(),

            paginatedBranchOffices: [],
            matrix: null,
            errors: [],
        }
    },
    getters: {
        getBranchOffices: state => state.branchOffices,
        getSelectedBranchOffice: state => (state.branchOffice?.id ? state.branchOffice : {}),
        getBranchOfficeById: state => (id = 0) => (id ? state.branchOffices.find(c => c.id === id) : {}) ?? {},

        getActiveBranchOffices: state => {
            let activeBranches = state.activeBranchOffices.filter(c => c.statusId === 2);
            return activeBranches;
        },
        getSelectedActiveBranchOffice: state => (state.activeBranchOffice?.id ? state.activeBranchOffice : {}),
        getActiveBranchOfficeById: state => (id = 0) => (id ? state.activeBranchOffices.find(c => c.id === id) : {}) ?? {},

        getPaginatedBranchOffices: state => state.paginatedBranchOffices,
        getTotalBranchOffices: state => state.branchOffices.length,
        matrixExist: state => state.matrix,
        filterBranchOfficeBySearchString: state => (searchString = '') => {
            if (!searchString) return state.branchOffices;
            console.log(state.branchOffices);
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.branchOffices.filter(branchOffice => {

                const code = branchOffice.code ? branchOffice.code.toLowerCase() : '';
                const name = branchOffice.name ? branchOffice.name.toLowerCase() : '';
                const phone = branchOffice.phone ? branchOffice.phone.toLowerCase() : '';
                const address = branchOffice.address ? branchOffice.address.toLowerCase() : '';
                const userName = branchOffice.userRel?.name ? branchOffice.userRel?.name.toLowerCase() : '';
                const userLastName = branchOffice.userRel?.lastName ? branchOffice.userRel?.lastName.toLowerCase() : '';
                const status = branchOffice.statusRel?.name ? branchOffice.statusRel?.name.toLowerCase() : '';

                return code.includes(lowerCaseSearchString) ||
                    name.includes(lowerCaseSearchString) ||
                    phone.includes(lowerCaseSearchString) ||
                    address.includes(lowerCaseSearchString) ||
                    (userName + " " + userLastName).includes(lowerCaseSearchString) ||
                    status.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setSelectedBranchOffice(id) {
            this.branchOffice = this.getBranchOfficeById(id);
        },
        setSelectedActiveBranchOffice(id) {
            this.activeBranchOffice = this.getActiveBranchOfficeById(id);
        },
        UPDATE_BRANCH_OFFICE(branchOffice) {
            const index = this.branchOffices.findIndex(c => c.id === branchOffice.id);
            if (index !== -1) {
                this.branchOffices.splice(index, 1, branchOffice);
            }
        },
        DELETE_BRANCH_OFFICE(id) {
            const index = this.branchOffices.findIndex(c => c.id === id);
            if (index !== -1) {
                this.branchOffices.splice(index, 1);
            }
        },
        setPaginatedBranchOffices(branchOffices) { this.paginatedBranchOffices = branchOffices },
        updatePaginatedBranchOffice(branchOffice) {
            const index = this.paginatedBranchOffices.findIndex(c => c.id === branchOffice.id);
            if (index !== -1) {
                this.paginatedBranchOffices.splice(index, 1, branchOffice);
            }
        },
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchBranchOffices() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.branchOfficeApi.get(`/api/BranchOffices/ByCompanyId/${useAuthStore().user.companyId}`);
                if (data) {
                    const branchOffices = data.map(c => new BranchOffice(c));
                    this.branchOffices = branchOffices;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error("Error del servicio al cargar las sucursales.");
                    $toast.info("Contacte al administrador del sistema.");
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error("Error de red al cargar las sucursales.");
                    $toast.info("Verifique su conexión a Internet.");
                } else {
                    // Otro tipo de error
                    $toast.error("Ocurrió un error al cargar las sucursales.");
                    $toast.info("Contacte al administrador del sistema.");
                }
            }
        },
        async fetchActiveBranchOffices() {
            try {
                const { data } = await service.branchOfficeApi.get(`/api/branchOffices/ByCompanyId/${useAuthStore().user.companyId}/active`);
                if (data) {
                    const branchOffices = data.map(c => new BranchOffice(c));
                    this.activeBranchOffices = branchOffices;
                }
            } catch (error) {
                this.setErrors(error);
                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error("Error del servicio al cargar las sucursales.");
                    $toast.info("Contacte al administrador del sistema.");
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error("Error de red al cargar las sucursales.");
                    $toast.info("Verifique su conexión a Internet.");
                } else {
                    // Otro tipo de error
                    $toast.error("Ocurrió un error al cargar las sucursales.");
                    $toast.info("Contacte al administrador del sistema.");
                }
            }
        },
        async createBranchOffice(branchOffice) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.branchOfficeApi.post(`/api/branchOffices?userId=${branchOffice.userRel.id}`, branchOffice);
                if (data && data.status) {
                    const branchOffice = new BranchOffice(data.data);
                    this.branchOffices.unshift(branchOffice);
                    $toast.success("Registro creado correctamente.");
                } else if (data || !data.status) {
                    this.setErrors(data.error);
                    $toast.error("Ocurrió un error al crear el registro.");
                    $toast.info("Contacte al administrador del sistema.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Error de red al crear el registro.");
                $toast.info("Verifique su conexión a Internet.");
            }
        },
        async updateBranchOffice(branchOffice) {
            try {
                const { data } = await service.branchOfficeApi.put(`/api/branchOffices?userId=${branchOffice.userRel.id}`, branchOffice);
                if (data && data.status) {
                    const branchOffice = new BranchOffice(data.data);
                    const index = this.branchOffices.findIndex(c => c.id === branchOffice.id);
                    if (index !== -1) {
                        this.branchOffices.splice(index, 1, branchOffice);
                    }
                    $toast.success("Registro actualizado correctamente.");
                } else if (data || !data.status) {
                    this.setErrors(data?.error);
                    $toast.error("Ocurrió un error al actualizar el registro.");
                    $toast.info("Contacte al administrador del sistema.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Error de red al actualizar el registro.");
                $toast.info("Verifique su conexión a Internet.");
            }
        },
        async checkMatrixExistenceByCompany() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.branchOfficeApi.get(`/api/branchOffices/matrixExistenceByCompany/${useAuthStore().user.companyId}`);
                if (data && data.status) {
                    const total = data.data;
                    this.matrix = total;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Error de red al actualizar el registro.");
                $toast.info("Verifique su conexión a Internet.");
            }
        }
    }
});