<template>
    <table class="table table-hover text-nowrap">
        <thead>
            <tr>
                <th @click="sortBy('referenceNumber')">
                    Folio de productos
                    <span v-if="sortKey === 'referenceNumber'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('branchOffice.name')">
                    Matriz y/o Sucursales
                    <span v-if="sortKey === 'branchOffice.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('description')">
                    Descripción
                    <span v-if="sortKey === 'description'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('productType.name')">
                    Tipo de producto
                    <span v-if="sortKey === 'productType.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('rateType.name')">
                    Tipo de Tasa
                    <span v-if="sortKey === 'rateType.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('referenceRate.name')">
                    Tasa de Referencia
                    <span v-if="sortKey === 'referenceRate.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('penaltyChargeRate.name')">
                    Tipo de Tasa Moratorio
                    <span v-if="sortKey === 'penaltyChargeRate.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('penaltyCharge')">
                    Moratorio
                    <span v-if="sortKey === 'penaltyCharge'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('guarantee.name')">
                    Garantía
                    <span v-if="sortKey === 'guarantee.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('loanTerm.name')">
                    Tipo de Plazo
                    <span v-if="sortKey === 'loanTerm.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('numberOfTerms')">
                    Plazo máximo
                    <span v-if="sortKey === 'numberOfTerms'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('maxAmount')">
                    Monto máximo
                    <span v-if="sortKey === 'maxAmount'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('currencyType.code')">
                    Moneda
                    <span v-if="sortKey === 'currencyType.code'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('tax.name')">
                    Impuesto
                    <span v-if="sortKey === 'tax.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('status.name')">
                    Estatus
                    <span v-if="sortKey === 'status.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in sortedData" :key="item.id" v-if="sortedData.length != 0" :class="{ statusBaja: isDisabled(item) }">
                <td class="sorting_1 dtr-control">{{ item.referenceNumber }}</td>
                <td>{{ item.branchOffice.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.productType.name }}</td>
                <td>{{ item.rateType.name }}</td>
                <td>{{ item.referenceRate.name }}</td>
                <td>{{ item.penaltyChargeRate.name }}</td>
                <td>{{ item.penaltyCharge }}</td>
                <td>{{ item.guarantee.name }}</td>
                <td>{{ item.loanTerm.name }}</td>
                <td>{{ item.numberOfTerms }}</td>
                <td>{{ $filters.formatNumber(item.maxAmount) }}</td>
                <td>{{ item.currencyType.code }}</td>
                <td>{{ item.tax.name }}</td>
                <td>{{ item.status.name }}</td>
                <td>
                    <PrimaryButton :disabled="isDisabled(item)" :class="[(isDisabled(item)) ? 'btn-secondary':'btn-success']" data-toggle="modal" data-target="#modal-lg"
                        @click.prevent="editProduct(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="15" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
            sortKey: '',
            sortOrder: 1, // 1 for ascending, -1 for descending
        };
    },
    emits: ['click-on-edit-btn', 'click-on-delete-btn'],
    props: {
        list: {
            type: Array,
            default: () => [],
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        sortedData() {
            let sortedList = [...this.list];
            if (this.sortKey) {
                sortedList.sort((a, b) => {
                    let aValue = this.getNestedValue(a, this.sortKey);
                    let bValue = this.getNestedValue(b, this.sortKey);

                    if (aValue < bValue) return -1 * this.sortOrder;
                    if (aValue > bValue) return 1 * this.sortOrder;
                    return 0;
                });
            }
            return this.paginate(sortedList);
        }
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1; // Toggle the sort order
            } else {
                this.sortOrder = 1; // Reset to ascending if a new column is sorted
            }
            this.sortKey = key;
        },
        getNestedValue(obj, key) {
            return key.split('.').reduce((o, i) => o[i], obj);
        },
        paginate(list) {
            if (!list || list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            return list.slice(start, start + this.itemsPerPage);
        },
        editProduct(product) {
            this.$emit('click-on-edit-btn', product);
        },
        deleteProduct(product) {
            this.$emit('click-on-delete-btn', product);
        },
        isDisabled(item) {
            return item.status.name === "Baja";
        }
    }
}
</script>