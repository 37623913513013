import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { sharedStore } from '@/pinia/sharedStore.module';
import LoanApplication from '@/Models/LoanApplications/LoanApplication';
import Question from '@/Models/LoanApplications/Question';

import * as service from '@/services';

const $toast = useToast();

export const useIndividualLoanApplicationStore = defineStore('individualLoanApplications', {
    persist: false,
    state: () => {
        return {
            mixedLoanApplications: computed({
                get() { return sharedStore().mixedLoanApplications },
                set(val) { sharedStore().mixedLoanApplications = val }
            }),
            loanApplications: computed({
                get() { return sharedStore().loanApplications },
                set(val) { sharedStore().loanApplications = val }
            }),
            loanApplication: computed({
                get() { return sharedStore().loanApplication },
                set(val) { sharedStore().loanApplication = val }
            }),
            individualLoanApplication: new LoanApplication({}),
            unratedLoanApplications: computed({
                get() { return sharedStore().unratedLoanApplications},
                set(val) { sharedStore().unratedLoanApplications = val }
            }),
            questions: [],
            applicantTypes: [],
            errors: null,
        }
    },
    getters: {
        getMixedLoanApplications: state => state.mixedLoanApplications,
        getLoanApplicationById: state => id => {
            if (id === 0 || id === null) return new LoanApplication({});
            if (state.mixedLoanApplications.length != 0) return state.mixedLoanApplications.find(la => la.id === id);
            if (state.loanApplications.length != 0) return state.loanApplications.find(la => la.id === id);
            return [];           
        },
        getActiveMixedLoanApplications: state => state.mixedLoanApplications.filter(la => {
            if (la.status.name === "Sin Calificar") {
                return la;
            }
        }),
        getIndividualLoanApplication: state => state.individualLoanApplication,
        getQuestions : state => state.questions,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        // TODO: Ver manera de mover método al sharedStore
        setSelectedLoanApplication(id) {
            this.loanApplication = this.getLoanApplicationById(id);
        },
        async fetchMixedLoanApplications() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get('/api/individualLoanApplications/mixed');
                if (data && data.status) {
                    const mixedLP = data.data;
                    this.mixedLoanApplications = mixedLP.map((la) => new LoanApplication(la)); 
                }
                useLoadingStore().loading = false;
            } catch (error) {
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar las solicitudes.");
            }
        },
        async fetchIndividualLoanApplicationsByProspectusId(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/individualLoanApplications/prospectus/${id}`);
                if (data && data.status) {
                    const loanApplications = data.data;
                    this.loanApplications = loanApplications.map((la) => new LoanApplication(la));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar las solicitudes.");
            }
        },
        // The getter for unrated LoanApplications is in the sharedStore module:
        async fetchUnratedIndividualLoanApplicationsByProspectusId(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/individualLoanApplications/prospectus/${id}/unrated`);
                if (data && data.status) {
                    const loanApplications = data.data;
                    this.unratedLoanApplications = loanApplications.map((la) => new LoanApplication(la));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar las solicitudes.");
            }
        },
        async fetchIndividualLoanApplicationById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/individualLoanApplications/${id}`);
                if (data && data.status) {
                    this.individualLoanApplication = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar la solicitud.");
            }
        },
        async getIndividualLoanApplicationById(id) {
            return new Promise((resolve, reject) => {
                service.loanApplicationsApi.get(`/api/individualLoanApplications/${id}`)
                .then(({data}) => {
                    if (data) {
                        resolve(data.data || {});
                    }
                })
                .catch((error) => {
                    reject(error);
                })
            });
        },
        async fetchQuestions() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get('/api/questions');
                if (data && data.status) {
                    const questions = data.data;
                    this.questions = questions.map(q => new Question(q));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar las preguntas.");
            }
        },
        async fetchQuestionsByGroupNumber(groupNumber) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/questions/${groupNumber}`);
                if (data && data.status) {
                    const questions = data.data;
                    this.questions = questions.map(q => new Question(q));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar las preguntas.");
            }
        },
        async createIndividualLoanApplication(individualData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.post('/api/individualLoanApplications', individualData);
                if (data && data.status) {
                    // const loanApplication = new LoanApplication(data.data);
                    this.mixedLoanApplications.unshift(data.data);
                    $toast.success("Solicitud creada correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al crear la solicitud.");
            }
        },
        async updateIndividualLoanApplication(individualData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.put('/api/individualLoanApplications', individualData);
                if (data && data.status) {
                    this.individualLoanApplication = new LoanApplication(data.data);
                    const index = this.mixedLoanApplications.findIndex(c => c.id === this.individualLoanApplication.id);
                    if (index !== -1) {
                        this.mixedLoanApplications.splice(index, 1, this.individualLoanApplication);
                        this.mixedLoanApplications.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                    $toast.success("Solicitud actualizada correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al actualizar la solicitud.");
            }
        }
    }
});