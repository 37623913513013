<template>
  <Form ref="form" @submit="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Subir documentación digitalizada</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Información</h3>
        </div>
        <div class="row card-body">
          <div class="form-group col-sm-6">
            <label for="branchOfficeId">Matriz y/o Sucursales:</label>
            <BranchOfficesSelect v-model="selectedBranchOfficeId" :name="'branchOfficeId'" :options="activeBranchOfficesOptions"></BranchOfficesSelect>
          </div>
          <div class="col-sm-6 btn-search-table">
            <PrimaryButton v-if="enableProspectsBtn" class="btn btn-success" @click.prevent="openTable(2)"><i class="fa fa-search"></i>Seleccionar Prospecto</PrimaryButton>
          </div>

          <div class="form-group col-sm-12">
            <label for="ProspectFullname">Prospecto:</label>
            <Field v-model="getProspectusFullname" type="text" class="form-control col-sm-9" name="ProspectFullname" disabled/>
          </div>

          <div class="form-group col-sm-6">
            <label for="recordTypeId">Tipos de expendiente:</label>
            <RecordTypesSelect v-model="selectedRecordTypeId" :name="'recordTypeId'" :options="activeRecordsOptions"></RecordTypesSelect>
          </div>
          <div class="col-sm-6 btn-search-table">
            <PrimaryButton v-if="enableRecordsBtn" class="btn btn-success" @click.prevent="openTable(3)"><i class="fa fa-search"></i>Seleccionar Expediente</PrimaryButton>
          </div>
          <div class="form-group col-sm-6">
            <label for="referenceNumber">Folio de Expediente:</label>
            <Field
              v-model="financialRecord.referenceNumber"
              type="text"
              class="form-control"
              name="referenceNumber"
              disabled
            />
          </div>
          <div class="form-group col-sm-6">
            <label for="name">Nombre de Expediente:</label>
            <Field
              v-model="financialRecord.name"
              type="text"
              class="form-control"
              name="name"
              disabled
            />
          </div>
          <div class="form-group col-sm-12">
            <label for="statusId">Estatus:</label>
            <StatusSelect v-model="selectedStatusId" :name="'statusId'" :options="statusOptions"></StatusSelect>
          </div>
        </div>
      </div>

      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Documentación</h3>
        </div>
        <div class="card-body">
          <div class="row" v-show="getActiveChecklists">
            <div
              class="info-box col-12 col-sm-12"
              v-for="(element, idx) in getActiveChecklists"
              :key="idx"
            >
              <div class="form-group">
                <label :for="'exampleInputFile'+idx">{{ element.document.documentName }}{{ (element.remarks) ? ` (${element.remarks})` : '' }}:</label>
                <div class="input-group">
                  <label>
                    <input
                    type="file"
                    ref="fileInputs"
                    class=""
                    :id="'exampleInputFile'+idx"
                    accept=".jpg, .jpeg, .png, .gif, .pdf"
                    @change="handleFileChange(idx)"
                  />
                  <span class="file-custom"></span>
                  </label>
                  
                </div>
                <!-- <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      :id="'exampleInputFile'+idx"
                      accept=".jpg, .jpeg, .png, .gif, .pdf"
                    />
                    <label class="custom-file-label" :for="'exampleInputFile'+idx">
                      Elige un archivo
                    </label>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row" v-show="getActiveChecklists.length === 0">
            <p class="">Seleccione un Tipo de Expediente y un Expediente para mostrar una lista de documentos.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        <!-- TODO: Change this to a computed property -->
        {{ $getLocaleMessage('buttons', 'cancel') }}
      </button>
      <PrimaryButton class="btn btn-primary" type="submit">
        {{ $getLocaleMessage('buttons', 'create') }}
      </PrimaryButton>
    </div>
  </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from "pinia";
import { useBranchOfficesStore } from "@/pinia/branchOffices.module";
import { useRecordTypesStore } from "@/pinia/recordTypes.module";
import { useIndividualProspectsStore } from "@/pinia/Prospects/individual.module";
import { financialRecordsStore } from "@/pinia/financialRecords.module";
import { checklistsStore } from '@/pinia/checklists.module';
import { useStatusStore } from '@/pinia/status.module';
import { sharedStore } from "@/pinia/sharedStore.module";

import BranchOfficesSelect from "@/Components/Selects/SelectField.vue";
import RecordTypesSelect from "@/Components/Selects/SelectField.vue";
import StatusSelect from "@/Components/Selects/SelectField.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";

import Prospectus from '@/Models/Prospects';
import FinancialRecord from '@/Models/FinancialRecord';
import RecordsProspects from '@/Models/RecordsProspects';
import RecordFileForm from '@/Models/RecordFileForm';

export default {
  components: {
    PrimaryButton,
    StatusSelect,
    BranchOfficesSelect,
    RecordTypesSelect,
  },
  mixins: [],
  data: () => ({
    recordProspectus: new RecordsProspects({}),
    recordFileFormDto: new RecordFileForm({}),
    selectedBranchOfficeId: null,
    selectedRecordTypeId: null,
    selectedStatusId: null,
    prospectId: null,
    files: {},
    filesId: [],
    fileInputs: [],
    dataValues: {},
  }),
  emits: ['selected-branch-office', 'selected-record-type', 'selected-status', 'change-modal-content'],
  props: {
    prospectus: {
      type: Object,
      default: () => new Prospectus({}),
    },
    financialRecord: {
      type: Object,
      default: () => new FinancialRecord({}),
    },
    tempValues: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
    ...mapState(useRecordTypesStore, ['getRecordTypes', 'getSelectedRecordType']),
    ...mapState(useIndividualProspectsStore, ['getAllProspects', 'getSelectedProspectus', 'getProspectBySelected']),
    ...mapState(sharedStore, ['getProspectusFullname']),
    ...mapState(financialRecordsStore, ['getFinancialRecords', "getActiveDocuments"]),
    ...mapState(checklistsStore, ['getActiveChecklists']),
    ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
    activeBranchOfficesOptions() {
      return (this.getActiveBranchOffices) ? this.branchOfficeOptions(this.getActiveBranchOffices) : [];
    },
    activeRecordsOptions() {
      return (this.getRecordTypes) ? helper.mapOptions(this.getRecordTypes) : [];
    },
    statusOptions() {
      return (this.getAllStatusByType(4)) ? helper.mapOptions(this.getAllStatusByType(4)) : [];
    },
    branchOfficeIsSelected() {
      return this.selectedBranchOfficeId !== null;
    },
    recordTypeIsSelected() {
      return this.selectedRecordTypeId !== null;
    },
    prospectusIsSelected() {
      return this.prospectus.prospectusId != null && this.prospectus.prospectusId !== 0;
    },
    enableProspectsBtn() {
        return this.branchOfficeIsSelected || this.prospectusIsSelected;
    },
    enableRecordsBtn() {
        return this.recordTypeIsSelected;
    },
    selectedRecordType() {
      return this.getSelectedRecordType.id;
    },
    selectedStatus() {
      return this.getSelectedStatus.id;
    },
  },
  methods: {
    ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
    ...mapActions(useRecordTypesStore,['setSelectedRecordType']),
    ...mapActions(useIndividualProspectsStore, ['fetchActiveMixedProspectsByBranchOffice']),
    ...mapActions(financialRecordsStore, ['fetchActiveFinancialRecordsByCompanyAndRecordType', 'createRecordProspectus']),
    ...mapActions(checklistsStore, ['fetchActiveChecklistsByRecordId']),
    ...mapActions(useStatusStore, ['setSelectedStatus']),
    onSubmit() {
      const formData = new FormData();
      // Add files to the FormData
      formData.append('recordFileFormDto.RecordProspectusDto.recordId', this.financialRecord.id);
      formData.append('recordFileFormDto.RecordProspectusDto.prospectusId', this.prospectus.prospectusId);
      formData.append('recordFileFormDto.RecordProspectusDto.branchOfficeId', this.selectedBranchOfficeId);
      formData.append('recordFileFormDto.RecordProspectusDto.statusId', this.selectedStatusId);

      Object.values(this.filesId).forEach((item, index) => {
        formData.append(`recordFileFormDto.FilesId[${index}]`, item);
      });

      Object.values(this.files).forEach((file, index) => {
        formData.append(`recordFileFormDto.Files`, file);
      });

      // for(var pair of formData.entries()) {
      //   console.log(pair[0]+ ', '+ pair[1]); 
      // }

      this.createRecordProspectus(formData);
      this.$refs.form.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal", true);
    },
    // Here we emit the values to the Index.vue component
    // to avoid losing them when the form component is unmouted and mounted again
    openTable(number) {
        this.dataValues.contentNumber = number;
        this.dataValues.branchOfficeIsSelected = this.branchOfficeIsSelected;
        this.dataValues.recordTypeIsSelected = this.recordTypeIsSelected;
        this.dataValues.branchOfficeId = this.selectedBranchOfficeId;
        this.dataValues.recordTypeId = this.selectedRecordTypeId;
        this.dataValues.statusId = this.selectedStatusId;
        this.$emit('show-prospects-table', this.dataValues);
    },
    handleFileChange(index) {
      const fileId = this.getActiveChecklists[index].documentId; // Get the Id from Documents table
      const fileInput = this.$refs.fileInputs[index];
      if (fileInput.files.length > 0) {
        // this.$set(this.files, index, fileInput.files[0]);
        this.filesId.push(fileId);
        this.files[index] = fileInput.files[0];
      } else {
        // this.$delete(this.files, index);
        delete this.files[index];
      }
    },
    branchOfficeOptions(data) {
        return helper.mapOptions(data);
    },
  },
  watch: {
    selectedBranchOfficeId: {
      handler(newValue) {
        if (newValue) {
          this.recordProspectus.branchOfficeId = newValue;
          this.fetchActiveMixedProspectsByBranchOffice(newValue);
        }
      }
    },
    selectedRecordTypeId: {
      handler(newValue) {
        if (newValue) {
          this.fetchActiveFinancialRecordsByCompanyAndRecordType(newValue);
        }
      }
    },
    prospectus: {
      handler(newProspectus) {
        if (newProspectus?.prospectusId) {
          this.recordProspectus.prospectusId = newProspectus.prospectusId;
        }
      },
      immediate: true,
    },
    financialRecord: {
      handler(newFinancialRecord) {
        if (newFinancialRecord?.id) {
          this.recordProspectus.recordId = newFinancialRecord.id;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.financialRecord.id) {
      this.fetchActiveChecklistsByRecordId(this.financialRecord.id);
    }
  },
  mounted() {
    this.selectedBranchOfficeId = (this.tempValues?.branchOfficeId ? this.tempValues.branchOfficeId : null);
    this.selectedRecordTypeId = (this.tempValues?.recordTypeId ? this.tempValues.recordTypeId : null);
    this.selectedStatusId = (this.tempValues?.statusId ? this.tempValues.statusId : null);
  },
};
</script>