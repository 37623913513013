import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { getLocaleMessage } from '@/services/MessagesService';

import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Checklist from '@/Models/RecordDocumentCondition';
import Condition from '@/Models/Condition';

import * as service from '@/services';

const $toast = useToast();

export const checklistsStore = defineStore('checklists', {
    persist: false,
    state: () => {
        return {
            checklists: reactive([]),
            checklist: new Checklist(),
            activeChecklists: reactive([]),
            activeChecklist: new Checklist(),

            conditions: reactive([]),
            condition: new Condition(),
            errors: reactive([]),
        };
    },
    getters: {
        getChecklists: state => state.checklists,
        getChecklist: state => state.checklist,
        getChecklistById: state => (id = 0) => (id ? state.checklists.find(c => c.id === id) : {}) ?? {},
        getActiveChecklists: state => state.activeChecklists,
        getActiveChecklist: state => state.activeChecklist,
        getActiveChecklistById: state => (id = 0) => (id ? state.activeChecklists.find(c => c.id === id) : {}) ?? {},
        getConditions: state => state.conditions,
        getCondition: state => state.condition,

        filterCheckListBySearchString: state => (searchString = '') => {
            if (!searchString) return state.checklists;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.checklists.filter(checklist => {

                const referenceNumber = checklist.financialRecord?.referenceNumber ? checklist.financialRecord?.referenceNumber.toLowerCase() : '';
                const documentName = checklist.document?.documentName ? checklist.document?.documentName.toLowerCase() : '';
                const remarks = checklist.remarks ? checklist.remarks.toLowerCase() : '';
                const conditionName = checklist.condition?.name ? checklist.condition?.name.toLowerCase() : '';
                const statusName = checklist.status?.name ? checklist.status?.name.toLowerCase() : '';

                return referenceNumber.includes(lowerCaseSearchString) ||
                    documentName.includes(lowerCaseSearchString) ||
                    remarks.includes(lowerCaseSearchString) ||
                    conditionName.includes(lowerCaseSearchString) ||
                    statusName.includes(lowerCaseSearchString)
            });
        },
        // getConditionById: state => (id = 0) => {
        //     if (id === 0 || id === null) return {};
        //     return state.conditions.find(c => c.id === id);
        // },
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        setSelectedChecklist(id) {
            this.checklist = this.getChecklistById(id);
        },
        setSelectedActiveChecklist(id) {
            this.activeChecklist = this.getActiveChecklistById(id);
        },
        unsetActiveCheckLists() {
            this.activeChecklists = [];
        },
        setSelectedCondition(id) {
            this.condition = this.getConditionById(id);
        },
        emptyTable() {
            this.checklists = [];
        },
        async fetchChecklistsByRecordId(recordId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Checklists/record/${recordId}`);
                if (data && data.status) {
                    this.checklists = data.data.map(chk => new Checklist(chk));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveChecklistsByRecordId(recordId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Checklists/record/${recordId}/active`);
                if (data && data.status) {
                    this.activeChecklists = data.data.map(chk => new Checklist(chk));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveChecklistsWithFilesByRecordId(recordId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/CheckLists/record/${recordId}/files/active`);
                if (data && data.status) {
                    this.activeChecklists = data.data.map(chk => new Checklist(chk));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetcheChecklistById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Checklists/${id}`);
                if (data && data.status) {
                    this.checklist = new Checklist(data.data);
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createChecklist(checklist) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.post(`/Checklists`, checklist);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('checklists', 'creatingSuccess'));
                    const checklist = new Checklist(data.data);
                    this.checklists.unshift(checklist);
                } else {
                    $toast.success(getLocaleMessage('checklists', 'creatingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateChecklist(checklist) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.put(`/Checklists`, checklist);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('checklists', 'editingSuccess'));
                    const updatedChecklist = new Checklist(data.data);
                    const index = this.checklists.findIndex(chk => chk.id === updatedChecklist.id);
                    if (index !== -1) {
                        this.checklists.splice(index, 1, updatedChecklist);
                        this.checklists.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                } else {
                    $toast.success(getLocaleMessage('checklists', 'editingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchConditions() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Checklists/conditions`);
                if (data && data.status) {
                    this.conditions = data.data.map(c => new Condition(c));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        }
    },
});
