const messages = {
    es: {
        common: {
            networkError: "Se produjo un error al intentar conectar. Por favor, verifica tu conexión a Internet.",
            loadingError: "Se produjo un error al cargar los datos.",
            backendError: "Error del servicio al cargar los datos.",
            responseError: "No se recibió respuesta del servidor.",
            noDataFound: "No se encontraron registros.",
            generalError: "Se produjo un error al realizar la operación. Contacte al administrador del sistema.",
        },
        formTitles: {
            create: "Crear",
            update: "Editando"
        },
        buttons: {
            addNewRecord: "Agregar Nuevo",
            updating: "Editando",
            create: "Agregar",
            update: "Actualizar",
            delete: "Eliminar",
            cancel: "Cancelar",
        },
        companies: {
            creatingSuccess: "Empresa creada correctamente.",
            editingSuccess: "Se ha actualizado la empresa.",
            fetchingError: "Se produjo un error al cargar las empresas.",
            creatingError: "Se produjo un error al crear la empresa.",
            editingError: "Se produjo un error al actualizar la empresa.",
        },
        products: {
            creatingSuccess: "Producto creado correctamente.",
            editingSuccess: "Se ha actualizado el producto.",
            fetchingError: "Se produjo un error al cargar los productos.",
            creatingError: "Se produjo un error al crear el producto.",
            editingError: "Se produjo un error al actualizar el producto.",
        },
        funders: {
            creatingSuccess: "Fondeadora creada correctamente.",
            editingSuccess: "Se ha actualizado la fondeadora.",
            fetchingError: "Se produjo un error al cargar las fondeadoras.",
            creatingError: "Se produjo un error al crear la fondeadora.",
            editingError: "Se produjo un error al actualizar la fondeadora.",
        },
        accounts: {
            creatingSuccess: "Cuenta creada correctamente.",
            editingSuccess: "Se ha actualizado la cuenta.",
            fetchingError: "Se produjo un error al cargar las cuentas.",
            creatingError: "Se produjo un error al crear la cuenta.",
            editingError: "Se produjo un error al actualizar la cuenta.",
        },
        productTypes: {
            creatingSuccess: "Tipo de producto creado correctamente.",
            editingSuccess: "Se ha actualizado el tipo de producto.",
            fetchingError: "Se produjo un error al cargar los tipos de productos.",
            creatingError: "Se produjo un error al crear el tipo de producto.",
            editingError: "Se produjo un error al actualizar el tipo de producto.",
        },
        rateTypes: {
            creatingSuccess: "Tipo de tasa creado correctamente.",
            editingSuccess: "Se ha actualizado el tipo de tasa.",
            fetchingError: "Se produjo un error al cargar los tipos de tasa.",
            creatingError: "Se produjo un error al crear el tipo de tasa.",
            editingError: "Se produjo un error al actualizar el tipo de tasa.",
        },
        referenceRates: {
            creatingSuccess: "Tasa de referencia creado correctamente.",
            editingSuccess: "Se ha actualizado la tasa de referencia.",
            fetchingError: "Se produjo un error al cargar las tasas de referencias.",
            creatingError: "Se produjo un error al crear la tasa de referencia.",
            editingError: "Se produjo un error al actualizar la tasa de referencia.",
        },
        penaltyChargeRates: {
            creatingSuccess: "Tasa de moratorio creado correctamente.",
            editingSuccess: "Se ha actualizado la tasa de moratorio.",
            fetchingError: "Se produjo un error al cargar las tasas de moratorio.",
            creatingError: "Se produjo un error al crear la tasa de moratorio.",
            editingError: "Se produjo un error al actualizar la tasa de moratorio.",
        },
        penaltyCharge: {
            creatingSuccess: "Moratorio creado correctamente.",
            editingSuccess: "Se ha actualizado el moratorio.",
            fetchingError: "Se produjo un error al cargar los moratorios.",
            creatingError: "Se produjo un error al crear el moratorio.",
            editingError: "Se produjo un error al actualizar el moratorio.",
        },
        guarantees: {
            creatingSuccess: "Garantía creada correctamente.",
            editingSuccess: "Se ha actualizado la garantía.",
            fetchingError: "Se produjo un error al cargar las garantías.",
            creatingError: "Se produjo un error al crear la garantía.",
            editingError: "Se produjo un error al actualizar la garantía.",
        },
        loanTerms: {
            creatingSuccess: "Tipo de plazo creado correctamente.",
            editingSuccess: "Se ha actualizado el tipo de plazo.",
            fetchingError: "Se produjo un error al cargar tipos de plazos",
            creatingError: "Se produjo un error al crear el tipo de plazo.",
            editingError: "Se produjo un error al actualizar el tipo de plazo.",
        },
        financialRecords: {
            creatingSuccess: "Expediente creado correctamente.",
            editingSuccess: "Se ha actualizado el expediente.",
            fetchingError: "Se produjo un error al cargar los expedientes.",
            creatingError: "Se produjo un error al crear el expediente.",
            editingError: "Se produjo un error al actualizar el expediente.",
        },
        documents: {
            creatingSuccess: "Documento creado correctamente.",
            editingSuccess: "Se ha actualizado el documento.",
            fetchingError: "Se produjo un error al cargar los documentos.",
            creatingError: "Se produjo un error al crear el documento.",
            editingError: "Se produjo un error al actualizar el documento.",
            deletingError: "Se produjo un error al eliminar el documento.",
        },
        checklists: {
            creatingSuccess: "Checklist creado correctamente.",
            editingSuccess: "Se ha actualizado el checklist.",
            fetchingError: "Se produjo un error al cargar los checklists.",
            creatingError: "Se produjo un error al crear el checklist.",
            editingError: "Se produjo un error al actualizar el checklist.",
            deletingError: "Se produjo un error al eliminar el checklist.",
        },
        users: {
            creatingSuccess: "Usuario creado correctamente.",
            editingSuccess: "Se ha actualizado el usuario.",
            deletingSuccess: "Se ha eliminado el usuario.",
            fetchingError: "Se produjo un error al cargar los usuarios.",
            creatingError: "Se produjo un error al crear el usuario.",
            editingError: "Se produjo un error al actualizar el usuario.",
            deletingError: "Se produjo un error al eliminar el usuario.",
        },
        prospects: {
            creatingSuccess: "Prospecto creado correctamente.",
            editingSuccess: "Se ha actualizado el prospecto.",
            deletingSuccess: "Se ha eliminado el prospecto.",
            fetchingError: "Se produjo un error al cargar los prospectos.",
            creatingError: "Se produjo un error al crear el prospecto.",
            editingError: "Se produjo un error al actualizar el prospecto.",
            deletingError: "Se produjo un error al eliminar el prospecto.",
            noProspects: "No se encontraron prospectos activos con la Matriz o Sucursal seleccionada.",
        },

    },
    en: {},
};

// export function getMessage(category, key, lang = 'es') {
//     return messages[category] && messages[category][key] ? messages[category][key][lang] : '';
// }

export function getLocaleMessage( messageType, messageKey, locale = 'es') {
    return messages[locale][messageType][messageKey];
}