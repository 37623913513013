<template>
    <Form ref="individual" @submit="onSubmit">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Solicitud - Persona Física
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">

            <!-- Section 1 -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title"></h3>
                </div>
                <div class="row card-body">
                    <div class="form-group col-sm-3">
                        <label for="applicantTypeId">Tipo de Solicitante:</label>
                        <ApplicantTypesSelect v-model="loanApplication.applicantTypeId" :name="'applicantTypeId'" :options="creditApplicantTypeOptions"></ApplicantTypesSelect>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="loanApplicationDate">Fecha de solicitud:</label>
                        <Field v-model="loanApplication.applicationDate" type="date" class="form-control" name="loanApplicationDate" :rules="minDate"/>
                        <ErrorMessage name="loanApplicationDate" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="financialFunderId">Fondeadora:</label>
                        <FinancialFundersSelect v-model="loanApplication.financialFunderId" :name="'financialFunderId'" :options="financialFundersOptions"></FinancialFundersSelect>
                    </div>
                </div>
            </div>

            <!-- Section 2 -->
            <div class="row">
                <div class="form-group col-sm-4">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="selectedBranchOfficeId" :name="'branchOfficeId'" :options="activeBranchOfficesOptions" :isDisabled="true"></BranchOfficesSelect>
                </div>
                <div class="form-group col-sm-4">
                    <label for="isBusinessActivity">Actividad Empresarial:</label>
                    <ActivitySelect v-model="selectedActivity" :name="'isBusinessActivity'" :options="staticOptions" :isDisabled="true"></ActivitySelect>
                </div>
                <div class="form-group col-sm-4">
                    <label for="rfcWithoutBusinessActivity">Inscrito al RFC sin Actividad Empresarial:</label>
                    <HasActivitySelect v-model="selectedHasActivity" :name="'rfcWithoutBusinessActivity'" :options="staticOptions" :isDisabled="true"></HasActivitySelect>
                </div>
            </div>

            <!-- Información Personal -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Información Personal</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="name">Nombre:</label>
                        <Field v-model="generalInformation.firstName" type="text" class="form-control" name="name" placeholder="Nombre" disabled
                         />
                        <ErrorMessage name="name" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="lastName">Apellido Paterno:</label>
                        <Field v-model="generalInformation.lastName" type="text" class="form-control" name="lastName" disabled
                            placeholder="Apellido Paterno" />
                        <ErrorMessage name="lastName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="mothersMaidenName">Apellido Materno:</label>
                        <Field v-model="generalInformation.mothersMaidenName" type="text" class="form-control" name="mothersMaidenName" disabled
                            placeholder="Apellido Materno" />
                        <ErrorMessage name="mothersMaidenName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Fecha de Nacimiento:</label>
                        <Field v-model="setSelectedDateOfBirth" type="date" class="form-control" name="date" disabled/>
                        <ErrorMessage name="date" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Entidad Federativa de Nacimiento:</label>
                        <Field v-model="generalInformation.birthState" type="text" class="form-control" name="birthState" disabled
                            placeholder="Entidad Federativa de Nacimiento" />
                        <ErrorMessage name="birthState" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Nacionalidad:</label>
                        <Field v-model="generalInformation.nationality" type="text" class="form-control" name="nationality" disabled
                            placeholder="Nacionalidad" />
                        <ErrorMessage name="nationality" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Género:</label>
                        <Field v-model="generalInformation.gender" type="text" class="form-control" name="gender" placeholder="Género" disabled/>
                        <ErrorMessage name="gender" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Estado Civil:</label>
                        <Field v-model="generalInformation.maritalStatus" type="text" class="form-control" name="maritalStatus" disabled
                            placeholder="Estado Civil" />
                        <ErrorMessage name="maritalStatus" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Régimen Matrimonial:</label>
                        <Field v-model="generalInformation.maritalRegime" type="text" class="form-control" name="maritalRegime" disabled
                            placeholder="Régimen Matrimonial"/>
                        <ErrorMessage name="maritalRegime" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">RFC:</label>
                        <Field v-model="generalInformation.rfc" type="text" class="form-control" name="rfc" placeholder="RFC" disabled
                         />
                        <ErrorMessage name="rfc" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">CURP:</label>
                        <Field v-model="generalInformation.curp" type="text" class="form-control" name="curp" placeholder="CURP" disabled
                         />
                        <ErrorMessage name="curp" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Tel. Casa:</label>
                        <Field v-model="generalInformation.homePhone" type="text" class="form-control" name="homePhone"
                            placeholder="Tel. Casa" disabled/>
                        <ErrorMessage name="homePhone" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Tel. Oficina:</label>
                        <Field v-model="generalInformation.officePhone" type="text" class="form-control" name="officePhone"
                            placeholder="Tel. Oficina" disabled/>
                        <ErrorMessage name="officePhone" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Tel. Celular:</label>
                        <Field v-model="generalInformation.cellPhone" type="text" class="form-control" name="cellPhone"
                            placeholder="Tel Celular" disabled />
                        <ErrorMessage name="cellPhone" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Correo Electrónico:</label>
                        <Field v-model="generalInformation.email" type="text" class="form-control" name="email"
                            placeholder="Correo Electrónico" disabled />
                        <ErrorMessage name="email" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Tipo de Identificación:</label>
                        <Field v-model="generalInformation.identificationType" type="text" class="form-control" name="identificationType"
                            placeholder="Tipo de Identificación" disabled />
                        <ErrorMessage name="identificationType" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">No. De Identificación:</label>
                        <Field v-model="generalInformation.identificationNumber" type="text" class="form-control"
                            name="identificationNumber" placeholder="No. De Identificación" disabled />
                        <ErrorMessage name="identificationNumber" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <!-- Domicilio Particular -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Domicilio Particular</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street">Calle:</label>
                        <Field v-model="homeAddress.street" type="text" class="form-control" name="street" placeholder="Calle" disabled />
                        <ErrorMessage name="street" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber">No. Exterior:</label>
                        <Field v-model="homeAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber" placeholder="No. Exterior" disabled />
                        <ErrorMessage name="exteriorNumber" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="interiorNumber">No. Exterior:</label>
                        <Field v-model="homeAddress.interiorNumber" type="text" class="form-control" name="interiorNumber" placeholder="No. Interior" disabled />
                        <ErrorMessage name="interiorNumber" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="district">Colonia:</label>
                        <Field v-model="homeAddress.district" type="text" class="form-control" name="district" placeholder="Colonia" disabled />
                        <ErrorMessage name="district" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="municipality">Delegación / Municipio:</label>
                        <Field v-model="homeAddress.municipality" type="text" class="form-control" name="municipality" placeholder="Delegación / Municipio" disabled />
                        <ErrorMessage name="municipality" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="city">Ciudad:</label>
                        <Field v-model="homeAddress.city" type="text" class="form-control" name="city" placeholder="Ciudad" disabled />
                        <ErrorMessage name="city" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="state">Estado:</label>
                        <Field v-model="homeAddress.state" type="text" class="form-control" name="state" placeholder="Estado" disabled />
                        <ErrorMessage name="state" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="postalCode">Código Postal:</label>
                        <Field v-model="homeAddress.postalCode" type="text" class="form-control" name="postalCode" placeholder="Código Postal" disabled />
                        <ErrorMessage name="postalCode" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="country">País:</label>
                        <Field v-model="homeAddress.country" type="text" class="form-control" name="country" placeholder="País" disabled />
                        <ErrorMessage name="country" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="propertyType">Tipo de Vivienda:</label>
                        <Field v-model="homeAddress.propertyType" type="text" class="form-control" name="propertyType" placeholder="Tipo de Vivienda" disabled />
                        <ErrorMessage name="propertyType" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                    <label for="antiguedadDomicilio">Antigüedad de Domicilio:</label>
                    <Field v-model="homeAddress.ageInHome" type="text" class="form-control" name="antiguedadDomicilio" placeholder="Antigüedad de Domicilio" disabled />
                        <ErrorMessage name="antiguedadDomicilio" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="mortgagedHome">Vivienda con Hipoteca:</label>
                        <MortgagedHome v-model="selectedMortgagedHome" :name="'mortgagedHome'" :options="staticOptions" :isDisabled="true"></MortgagedHome>
                    </div>
                </div>
            </div>

            <!-- Domicilio Fiscal -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Domicilio Fiscal</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street2">Calle:</label>
                        <Field v-model="fiscalAddress.street" type="text" class="form-control" name="street2" placeholder="Calle" disabled />
                        <ErrorMessage name="street2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber2">No. Exterior:</label>
                        <Field v-model="fiscalAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber2" placeholder="No. Exterior" disabled />
                        <ErrorMessage name="exteriorNumber2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="interiorNumber2">No. Exterior:</label>
                        <Field v-model="fiscalAddress.interiorNumber" type="text" class="form-control" name="interiorNumber2" placeholder="No. Interior" disabled />
                        <ErrorMessage name="interiorNumber2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="district2">Colonia:</label>
                        <Field v-model="fiscalAddress.district" type="text" class="form-control" name="district2" placeholder="Colonia" disabled />
                        <ErrorMessage name="district2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="municipality2">Delegación / Municipio:</label>
                        <Field v-model="fiscalAddress.municipality" type="text" class="form-control" name="municipality2" placeholder="Delegación / Municipio" disabled />
                        <ErrorMessage name="municipality2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="country2">País:</label>
                        <Field v-model="fiscalAddress.country" type="text" class="form-control" name="country2" placeholder="País" disabled />
                        <ErrorMessage name="country2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="city2">Ciudad:</label>
                        <Field v-model="fiscalAddress.city" type="text" class="form-control" name="city2" placeholder="Ciudad" disabled />
                        <ErrorMessage name="city2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="state2">Estado:</label>
                        <Field v-model="fiscalAddress.state" type="text" class="form-control" name="state2" placeholder="Estado" disabled />
                        <ErrorMessage name="state2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="postalCode2">Código Postal:</label>
                        <Field v-model="fiscalAddress.postalCode" type="text" class="form-control" name="postalCode2" placeholder="Código Postal" disabled />
                        <ErrorMessage name="postalCode2" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <!-- Información Financiera -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Información Financiera</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-4">
                        <label for="profession">Ocupación:</label>
                        <Field v-model="financialInformation.profession" type="text" class="form-control" name="profession" placeholder="Ocupación"
                            rules="required|max:255" />
                        <ErrorMessage name="profession" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="business">Giro/Actividad:</label>
                        <Field v-model="financialInformation.business" type="text" class="form-control" name="business" placeholder="Giro/Actividad"
                            rules="required|max:255" />
                        <ErrorMessage name="business" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="employersName">Nombre del Empleador:</label>
                        <Field v-model="financialInformation.employersName" type="text" class="form-control" name="employersName" placeholder="Nombre del Empleador"
                            rules="required|max:255" />
                        <ErrorMessage name="employersName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="seniority">Antigüedad:</label>
                        <Field v-model="financialInformation.seniority" type="text" class="form-control" name="seniority" placeholder="Antigüedad"
                            rules="required|max:255" />
                        <ErrorMessage name="seniority" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="employersAddress">Domicilio del Empleador (Calle, Número, Ciudad, Estado):</label>
                        <Field v-model="financialInformation.employersAddress" as="textarea" type="text" class="form-control" name="employersAddress" placeholder="Domicilio del Empleador"
                            rules="required|max:255" />
                        <ErrorMessage name="employersAddress" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="employersEmail">Correo electrónico del Empleador:</label>
                        <Field v-model="financialInformation.employersEmail" type="text" class="form-control" name="employersEmail" placeholder="Correo electrónico"
                            rules="required|max:255" />
                        <ErrorMessage name="employersEmail" class="input-error"></ErrorMessage>
                    </div>
                    <!-- TODO: These 3 blocks of fields must start at zeros (0 by default)-->
                    <div class="form-group col-sm-4">
                        <h5>Ingresos Mensuales</h5>
                        <label for="salaries">Sueldos $:</label>
                        <Field v-model="monthlyIncomes.salaries" type="number" class="form-control" name="salaries" placeholder="Sueldos"
                            rules="required" />
                        <ErrorMessage name="salaries" class="input-error"></ErrorMessage>
                        
                        <label for="rentalIncome">Rentas $:</label>
                        <Field v-model="monthlyIncomes.rentalIncome" type="number" class="form-control" name="rentalIncome" placeholder="Rentas"
                            rules="required" />
                        <ErrorMessage name="rentalIncome" class="input-error"></ErrorMessage>

                        <label for="dividends">Dividendos $:</label>
                        <Field v-model="monthlyIncomes.dividends" type="number" class="form-control" name="dividends" placeholder="Dividendos"
                            rules="required" />
                        <ErrorMessage name="dividends" class="input-error"></ErrorMessage>

                        <label for="interests">Intereses $:</label>
                        <Field v-model="monthlyIncomes.interests" type="number" class="form-control" name="interests" placeholder="Intereses"
                            rules="required" />
                        <ErrorMessage name="interests" class="input-error"></ErrorMessage>

                        <label for="other1">Otros $:</label>
                        <Field v-model="monthlyIncomes.other" type="number" class="form-control" name="other1" placeholder="Otros"
                            rules="required" />
                        <ErrorMessage name="other1" class="input-error"></ErrorMessage>

                        <label for="total1">Total $:</label>
                        <Field v-model="getMonthlyIncomesTotal" type="number" class="form-control" name="total1" disabled />
                        <ErrorMessage name="total1" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <h5>Gastos Mensuales</h5>
                        <label for="housing">Vivienda $:</label>
                        <Field v-model="monthlyExpenses.housing" type="number" class="form-control" name="housing" placeholder="Vivienda"
                            rules="required" />
                        <ErrorMessage name="housing" class="input-error"></ErrorMessage>

                        <label for="maintenance">Manutención $:</label>
                        <Field v-model="monthlyExpenses.maintenance" type="number" class="form-control" name="maintenance" placeholder="Manutención"
                            rules="required" />
                        <ErrorMessage name="maintenance" class="input-error"></ErrorMessage>

                        <label for="tuition">Colegiatura $:</label>
                        <Field v-model="monthlyExpenses.tuition" type="number" class="form-control" name="tuition" placeholder="Colegiatura"
                            rules="required" />
                        <ErrorMessage name="tuition" class="input-error"></ErrorMessage>

                        <label for="entertainment">Diversión $:</label>
                        <Field v-model="monthlyExpenses.entertainment" type="number" class="form-control" name="entertainment" placeholder="Diversión"
                            rules="required" />
                        <ErrorMessage name="entertainment" class="input-error"></ErrorMessage>

                        <label for="other2">Otros $:</label>
                        <Field v-model="monthlyExpenses.other" type="number" class="form-control" name="other2" placeholder="Otros"
                            rules="required" />
                        <ErrorMessage name="other2" class="input-error"></ErrorMessage>

                        <label for="total2">Total $:</label>
                        <Field v-model="getMonthlyExpensesTotal" type="number" class="form-control" name="total2" disabled />
                        <ErrorMessage name="total2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <h5>Número de dependientes económicos</h5>
                        <label for="spouse">Cónyugue No.:</label>
                        <Field v-model="economicDependents.spouse" type="number" class="form-control" name="spouse" placeholder="Cónyugue"
                            rules="required" />
                        <ErrorMessage name="spouse" class="input-error"></ErrorMessage>

                        <label for="children">Hijos No.:</label>
                        <Field v-model="economicDependents.children" type="number" class="form-control" name="children" placeholder="Hijos"
                            rules="required" />
                        <ErrorMessage name="children" class="input-error"></ErrorMessage>

                        <label for="parents">Padres No.:</label>
                        <Field v-model="economicDependents.parents" type="number" class="form-control" name="parents" placeholder="Padres"
                            rules="required" />
                        <ErrorMessage name="parents" class="input-error"></ErrorMessage>

                        <label for="siblings">Hermanos No.:</label>
                        <Field v-model="economicDependents.siblings" type="number" class="form-control" name="siblings" placeholder="Hermanos"
                            rules="required" />
                        <ErrorMessage name="siblings" class="input-error"></ErrorMessage>

                        <label for="other3">Otros No.:</label>
                        <Field v-model="economicDependents.other" type="number" class="form-control" name="other3" placeholder="Otros"
                            rules="required" />
                        <ErrorMessage name="other3" class="input-error"></ErrorMessage>

                        <label for="total3">Total No.:</label>
                        <Field v-model="getEconomicDependentsTotal" type="number" class="form-control" name="total3" disabled />
                        <ErrorMessage name="total3" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="currentEmploymentStatus">Estado laboral actual:</label>
                        <Field v-model="financialInformation.currentEmploymentStatus" type="text" class="form-control" name="currentEmploymentStatus" placeholder="Estado laboral actual"
                            rules="required|max:255" />
                        <ErrorMessage name="currentEmploymentStatus" class="input-error"></ErrorMessage>
                    </div>
                    <div class="for-group col-sm-9">
                        <label for="spouseFullName">Nombre completo del Cónyuge:</label>
                        <Field v-model="financialInformation.spouseFullName" type="text" class="form-control" name="spouseFullName" placeholder="Nombre completo del Cónyuge" rules="max:255" />
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="otherIncomes">Especifique los otros ingresos:</label>
                        <Field v-model="financialInformation.otherIncomes" as="textarea" type="text" class="form-control" name="otherIncomes" placeholder="otros ingresos" rules="max:500" />
                    </div>
                </div>
            </div>

            <!-- Referencias (Solicitante) -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Referencias (Solicitante)</h3>
                </div>

                <div class="row card-body">
                    <div class="row col-sm-12" v-for="(reference, index) in applicantLoanReferences">
                        <div class="form-group col-sm-5">
                            <label :for="'fullName1'+index">Nombre completo:</label>
                            <Field v-model="reference.fullName" type="text" class="form-control" :name="'fullName1'+index" placeholder="Nombre completo"
                            rules="max:255" />
                            <ErrorMessage :name="'fullName1'+index" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-4">
                            <label :for="'address1'+index">Domicilio (Calle, Número, Ciudad, Estado):</label>
                            <Field v-model="reference.address" type="text" class="form-control" :name="'address1'+index" placeholder="Domicilio"
                                rules="max:255" />
                            <ErrorMessage :name="'address1'+index" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-3">
                            <label :for="'phoneNumber1'+index">Teléfono:</label>
                            <Field v-model="reference.phoneNumber" type="text" class="form-control" :name="'phoneNumber1'+index" placeholder="Teléfono"
                                rules="max:255" />
                            <ErrorMessage :name="'phoneNumber1'+index" class="input-error"></ErrorMessage>
                        </div>
                    </div>

                    <div class="row col-sm-12" v-if="applicantLoanReferences.length > 0">
                        <h6 class="col-sm-12 subtitle-h6">Confirmación de Referencias (Para ser llenado por la empresa)</h6>
                        <div class="form-group col-sm-3">
                            <label for="verificationDate">Fecha de verificación:</label>
                            <Field v-model="applicantLoanReferences[0].verificationDate" type="date" class="form-control" name="verificationDate" />
                            <ErrorMessage name="verificationDate" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-5">
                            <label for="workersName">Nombre del trabajador:</label>
                            <Field v-model="applicantLoanReferences[0].workersName" type="text" class="form-control" name="workersName" placeholder="Nombre del trabajador"
                                rules="max:255" />
                            <ErrorMessage name="workersName" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="position1">Puesto:</label>
                            <Field v-model="applicantLoanReferences[0].position" type="text" class="form-control" name="position1" placeholder="Puesto"
                                rules="max:255" />
                            <ErrorMessage name="position1" class="input-error"></ErrorMessage>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Características del producto (Solicitante) -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Características del producto (Solicitante)</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-5">
                        <label for="comments">Nombre del producto:</label>
                        <Field v-model="quotation.comments" type="text" class="form-control" name="comments" disabled/>
                    </div>
                    <div class="form-group col-sm-5">
                        <label for="creditAmount">Importe:</label>
                        <Field v-model="quotation.creditAmount" type="text" class="form-control" name="creditAmount" disabled/>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="totalTerm">Plazo:</label>
                        <Field v-model="quotation.totalTerm" type="text" class="form-control" name="totalTerm" disabled/>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="purposeRequestedAmount">Destino del importe solicitado:</label>
                        <Field v-model="productDetails.purposeRequestedAmount" type="text" class="form-control" name="purposeRequestedAmount" placeholder="Destino del importe solicitado"
                            rules="required|max:255" />
                        <ErrorMessage name="purposeRequestedAmount" class="input-error"></ErrorMessage>
                    </div>
                    <h6 class="col-sm-12 subtitle-h6">(Uso Exclusivo de la empresa)</h6>
                    <div class="form-group col-sm-12">
                        <!-- <label for="authorizedAmount">Monto autorizado (Número y Letra):</label> -->
                        <label for="authorizedAmount">Monto autorizado:</label>
                        <Field v-model="productDetails.authorizedAmount" type="text" class="form-control" name="authorizedAmount" placeholder="Monto autorizado"
                            rules="required" />
                        <ErrorMessage name="authorizedAmount" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="totalTerm2">Plazo:</label>
                        <Field v-model="quotation.totalTerm" type="text" class="form-control" name="totalTerm2" disabled/>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="paymentDays">Días de Pago:</label>
                        <Field v-model="productDetails.paymentDays" type="text" class="form-control" name="paymentDays" placeholder="Días de Pago"
                            rules="required" />
                        <ErrorMessage name="paymentDays" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="annualRate">Tasa ordinaria:</label>
                        <Field v-model="annualRateToPercent" type="text" class="form-control" name="annualRate" disabled/>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="penaltyRate">Tasa Moratoria:</label>
                        <Field v-model="quotation.street" type="text" class="form-control" name="penaltyRate" />
                        <ErrorMessage name="penaltyRate" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="openingCommission">Comisión por Apertura:</label>
                        <Field v-model="openingCommissionToPercent" type="text" class="form-control" name="openingCommission" disabled/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="gracePeriod">Días de Gracia:</label>
                        <Field v-model="quotation.gracePeriod" type="text" class="form-control" name="gracePeriod" disabled/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="firstPaymentDay">Primer Pago:</label>
                        <Field v-model="productDetails.street" type="date" class="form-control" name="firstPaymentDay" />
                        <ErrorMessage name="firstPaymentDay" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="authorizedBy">Autorizado por (Nombre Completo):</label>
                        <Field v-model="productDetails.authorizedBy" type="text" class="form-control" name="authorizedBy" placeholder="Autorizado por"
                            rules="required|max:255" />
                        <ErrorMessage name="authorizedBy" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="date2">Fecha (DD/MM/AA):</label>
                        <Field v-model="productDetails.date" type="date" class="form-control" name="date2" placeholder="Fecha"
                            rules="required" />
                        <ErrorMessage name="date2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="position2">Puesto y Firma:</label>
                        <Field v-model="productDetails.position" type="text" class="form-control" name="position2" placeholder=""
                            rules="required|max:255" />
                        <ErrorMessage name="position2" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <!-- Pago domiciliado -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Pago domiciliado</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-12">
                        <div class="row">
                            <p class="col-sm-4">De ser aprobada la siguiente solicitud autorizo a:</p>
                            <Field v-model="directDebit.authorizedPersonName" type="text" class="form-control col-sm-8" name="authorizedPersonName"  placeholder="Nombre" rules="max:255" />
                            <ErrorMessage name="authorizedPersonName" class="input-error"></ErrorMessage>
                            <p class="col-sm-9">para realizar la transferencia del monto autorizado y aplicar los descuentos de forma automática a la cuenta número</p>
                            <Field v-model="directDebit.accountNumber" type="text" class="form-control col-sm-3" name="accountNumber"  placeholder="Cuenta" rules="max:255" />
                            <ErrorMessage name="accountNumber" class="input-error"></ErrorMessage>
                            <p class="col-sm-1">Clabe:</p> 
                            <Field v-model="directDebit.clabe" type="text" class="form-control col-sm-3" name="clabe" placeholder="Clabe" rules="max:255" />
                            <ErrorMessage name="clabe" class="input-error"></ErrorMessage>
                            <p class="col-sm-1">Banco:</p>
                            <Field v-model="directDebit.bank" type="text" class="form-control col-sm-3" name="bank" placeholder="Banco" rules="max:255" />
                            <ErrorMessage name="bank" class="input-error"></ErrorMessage>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Entrevista Personal del Solicitante -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Entrevista Personal del Solicitante</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-12">
                        <label for="question1">1.- ¿Realizará pagos en efectivo y cuál es el origen de los recursos?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <SelectAnswer v-model="answers[0].dataValue" :name="'answer1'" :options="staticAnswerOptions" @update="setNewAnswer(0, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-9">
                                <label for="origin">Origen</label>
                                <Field v-model="answers[1].dataValue" type="text" class="form-control" name="origin" placeholder="Origen" rules="max:500"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="question2">2.-¿El Importe solicitado será liquidado con recursos propios o por un tercero?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <SelectAnswer v-model="answers[2].dataValue" :name="'answer3'" :options="typesOptions" @update="setNewAnswer(2, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-9">
                                <label for="name2">Nombre del Tercero</label>
                                <Field v-model="answers[3].dataValue" type="text" class="form-control" name="name2" placeholder="Nombre del Tercero" rules="max:500"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="question3">3.- ¿Los recursos solicitados serán destinados para uso propio o un tercero disfrutara de ellos?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <SelectAnswer v-model="answers[4].dataValue" :name="'answer5'" :options="typesOptions" @update="setNewAnswer(4, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-9">
                                <label for="name3">Nombre del Tercero</label>
                                <Field v-model="answers[5].dataValue" type="text" class="form-control" name="name3" placeholder="Nombre del Tercero" rules="max:500"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="">4.- ¿Los recursos con los que se liquidara el monto solicitado proceden de actividades licitas?</label>
                        <SelectAnswer v-model="answers[6].dataValue" :name="'answer7'" :options="staticAnswerOptions" @update="setNewAnswer(6, $event)"></SelectAnswer>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="">5.- ¿Usted desempeña o ha desempeñado durante los últimos 12 meses funciones públicas o políticas destacadas dentro del territorio nacional, o extranjero?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <SelectAnswer v-model="answers[7].dataValue" :name="'answer8'" :options="staticAnswerOptions" @update="setNewAnswer(7, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-3">
                                <label for="position3">Puesto</label>
                                <Field v-model="answers[8].dataValue" type="text" class="form-control" name="position3" placeholder="Puesto" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="assignmentDate">Fecha de Asignación (DD/MM/AA):</label>
                                <Field v-model="answers[9].dataValue" type="date" class="form-control" name="assignmentDate"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="withdrawalDate">Fecha de Retiro (DD/MM/AA):</label>
                                <Field v-model="answers[10].dataValue" type="date" class="form-control" name="withdrawalDate" rules="max:500"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="">
                            6.- ¿Su cónyuge o algún familiar por consanguinidad o afinidad hasta segundo grado, 
                            desempeña o ha desempeñado durante los últimos 12 meses funciones públicas o políticas destacadas dentro del territorio nacional, 
                            o en el extranjero?
                        </label>
                        <div class="row align-end">
                            <div class="col-sm-4">
                                <SelectAnswer v-model="answers[11].dataValue" :name="'answer12'" :options="staticAnswerOptions" @update="setNewAnswer(11, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-4">
                                <label for="fullName">Nombre Completo:</label>
                                <Field v-model="answers[12].dataValue" type="text" class="form-control" name="fullName" placeholder="Nombre Completo" rules="max:500"/>
                            </div>
                            <div class="col-sm-4">
                                <label for="dependencia">Dependencia:</label>
                                <Field v-model="answers[13].dataValue" type="text" class="form-control" name="dependencia" placeholder="Dependencia" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="position4">Puesto:</label>
                                <Field v-model="answers[14].dataValue" type="text" class="form-control" name="position4" placeholder="Puesto" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="asignacion">Fecha de Asignación (DD/MM/AA):</label>
                                <Field v-model="answers[15].dataValue" type="date" class="form-control" name="asignacion" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="retiro">Fecha de Retiro (DD/MM/AA):</label>
                                <Field v-model="answers[16].dataValue" type="date" class="form-control" name="retiro" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="parentesco">Parentesco:</label>
                                <Field v-model="answers[17].dataValue" type="text" class="form-control" name="parentesco" placeholder="Parentesco" rules="max:500"/>
                            </div>
                        </div>
                    </div>
                    <!-- Fin de Preguntas -->

                    <!-- Entrevistador y Solicitiante -->
                    <div class="form-group col-sm-12">
                        <div class="row">
                            <div class="col-sm-4">
                                <label for="interviewer">Entrevista realizada por (Nombre completo):</label>
                                <Field v-model="interview.interviewer" type="text" class="form-control" name="interviewer" placeholder="Nombre Completo"
                                    rules="required|max:255" />
                                <ErrorMessage name="interviewer" class="input-error"></ErrorMessage>
                            </div>
                            <div class="col-sm-4">
                                <label for="applicantsName">Solicitante:</label>
                                <!-- TODO: Change input to select component (Opciones: Cliente Nuevo / Cliente Recurrente) -->
                                <Field v-model="loanApplication.applicantsName" type="text" class="form-control" name="applicantsName" placeholder="Solicitante" rules="required|max:255"/>
                                <ErrorMessage name="applicantsName" class="input-error"></ErrorMessage>
                            </div>
                            <div class="col-sm-4">
                                <label for="authorizer">Autorizado (Nombre completo):</label>
                                <Field v-model="interview.authorizer" type="text" class="form-control" name="authorizer" placeholder="Nombre Completo"
                                    rules="required|max:255" />
                                <ErrorMessage name="authorizer" class="input-error"></ErrorMessage>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-group">
                <label for="statusId">Estatus:</label>
                <StatusSelect v-model="loanApplication.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect> 
            </div>
        </div>

        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useApplicantTypesStore } from '@/pinia/LoanApplications/applicantTypes.module.js';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import ApplicantTypesSelect from '@/Components/Selects/SelectField.vue';
import FinancialFundersSelect from '@/Components/Selects/SelectField.vue';
import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import ActivitySelect from '@/Components/Selects/SelectField.vue';
import HasActivitySelect from '@/Components/Selects/SelectField.vue';
import MortgagedHome from '@/Components/Selects/SelectField.vue';
import SelectAnswer from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';

import IndividualData from '@/Models/Prospects/IndividualData';
import Prospectus from '@/Models/Prospects';
import GeneralInformation from '@/Models/GeneralInformation';
import FiscalAddress from '@/Models/FiscalAddress';
import IndividualLoanApplicationData from '@/Models/LoanApplications/IndividualLoanApplicationData';
import LoanApplication from '@/Models/LoanApplications/LoanApplication';
import BranchOffice from '@/Models/BranchOffice';
import MonthlyIncome from '@/Models/LoanApplications/MonthlyIncome';
import MonthlyExpense from '@/Models/LoanApplications/MonthlyExpense';
import EconomicDependent from '@/Models/LoanApplications/EconomicDependent';
import DirectDebit from '@/Models/LoanApplications/DirectDebit';
import FinancialInformation from '@/Models/LoanApplications/FinancialInformation';
import Reference from '@/Models/LoanApplications/Reference';
import ProductDetail from '@/Models/LoanApplications/ProductDetail';
import Interview from '@/Models/LoanApplications/Interview';
import Answer from '@/Models/LoanApplications/Answer';
import * as yup from 'yup';

export default {
    components: {
        PrimaryButton,
        ApplicantTypesSelect,
        FinancialFundersSelect,
        BranchOfficesSelect,
        StatusSelect,
        ActivitySelect,
        HasActivitySelect,
        MortgagedHome,
        SelectAnswer,
    },
    mixins: [],
    emits: ['close-modal'],
    data() {
        return {
            individualData: new IndividualData({}),
            prospectus: new Prospectus({}),
            generalInformation: new GeneralInformation({}),
            homeAddress: new FiscalAddress({}),
            fiscalAddress: new FiscalAddress({}),

            individualLoanApplicationData: new IndividualLoanApplicationData({}),
            loanApplication: new LoanApplication({ personTypeId: 1 }),
            branchOffice: new BranchOffice({}),
            financialInformation: new FinancialInformation({}),
            monthlyIncomes: new MonthlyIncome({}),
            monthlyExpenses: new MonthlyExpense({}),
            economicDependents: new EconomicDependent({}),
            directDebit: new DirectDebit({}),
            productDetails: new ProductDetail({}),
            // When a new reference is requested, we generate a new entry and push the data to an array
            reference: new Reference({}),
            // This array will contain a list of financialEntries:
            applicantLoanReferences: [
                new Reference({ referenceTypeId: 1 }),
                new Reference({ referenceTypeId: 2 }),
                new Reference({ referenceTypeId: 3 }),
            ],
            interview: new Interview({}),
            answers: [
                new Answer({ questionId: 1, answerTypeId: 1, dataLabel: '', itemOrder: 1 }),
                new Answer({ questionId: 1, answerTypeId: 2, dataLabel: 'Origen', itemOrder: 2 }),

                new Answer({ questionId: 2, answerTypeId: 1, dataLabel: '', itemOrder: 1 }),
                new Answer({ questionId: 2, answerTypeId: 2, dataLabel: 'Nombre del Tercero', itemOrder: 2 }),

                new Answer({ questionId: 3, answerTypeId: 1, dataLabel: '', itemOrder: 1 }),
                new Answer({ questionId: 3, answerTypeId: 2, dataLabel: 'Nombre del Tercero', itemOrder: 2 }),

                new Answer({ questionId: 4, answerTypeId: 1, dataLabel: '', itemOrder: 1 }),

                new Answer({ questionId: 5, answerTypeId: 1, dataLabel: '', itemOrder: 1 }),
                new Answer({ questionId: 5, answerTypeId: 2, dataLabel: 'Puesto', itemOrder: 2 }),
                new Answer({ questionId: 5, answerTypeId: 3, dataLabel: 'Fecha de Asignación (DD/MM/AA)', itemOrder: 3 }),
                new Answer({ questionId: 5, answerTypeId: 3, dataLabel: 'Fecha de Retiro (DD/MM/AA)', itemOrder: 4 }),

                new Answer({ questionId: 6, answerTypeId: 1, dataLabel: '', itemOrder: 1 }),
                new Answer({ questionId: 6, answerTypeId: 2, dataLabel: 'Nombre Completo', itemOrder: 2 }),
                new Answer({ questionId: 6, answerTypeId: 2, dataLabel: 'Dependencia', itemOrder: 3 }),
                new Answer({ questionId: 6, answerTypeId: 2, dataLabel: 'Puesto', itemOrder: 4 }),
                new Answer({ questionId: 6, answerTypeId: 3, dataLabel: 'Fecha de Asignación (DD/MM/AA)', itemOrder: 5 }),
                new Answer({ questionId: 6, answerTypeId: 3, dataLabel: 'Fecha de Retiro (DD/MM/AA)', itemOrder: 6 }),
                new Answer({ questionId: 6, answerTypeId: 2, dataLabel: 'Parentesco', itemOrder: 7 }),
            ],
            selectOptions: [
                {
                    id: 1,
                    name: 'No'
                },
                {
                    id: 2,
                    name: 'Si'
                },
            ],
            selectedTypes: [
                {
                    id: 1,
                    name: 'Propios'
                },
                {
                    id: 2,
                    name: 'Terceros'
                },
            ],
            answerOptions: [
                {
                    id: 1,
                    name: 'No'
                },
                {
                    id: 2,
                    name: 'Si'
                },
            ],
            selectedActivity: 0,
            selectedHasActivity: 0,
            selectedMortgagedHome: 0,
            selectedBranchOfficeId: null,
            activeBranchOfficesOptions: [],
        }
    },
    props: {
        // Here we receive the prospectus Id to make a new request to get all models related to the selected Prospectus:        
        prospectusId: {
            type: Number,
            required: true,
        },
        quotation: {
            type: Object,
            required: false,
            default: () => {
                return new Quotation({});
            }
        },
    },
    computed: {
        ...mapState(useIndividualProspectsStore, ['getSelectedProspectus']),
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        ...mapState(useFinancialFundersStore, ['getActiveFinancialFunders']),
        ...mapState(useIndividualLoanApplicationStore, ['getQuestions']),
        ...mapState(useApplicantTypesStore, ['getApplicantTypes']),
        creditApplicantTypeOptions() {
            return helper.mapOptions(this.getApplicantTypes);
        },
        financialFundersOptions() {
            return helper.mapOptions(this.getActiveFinancialFunders, 'id', 'anchor');
        },
        staticOptions() {
            return helper.mapOptions(this.selectOptions);
        },
        typesOptions() {
            return helper.mapOptions(this.selectedTypes);
        },
        staticAnswerOptions() {
            return helper.mapOptions(this.answerOptions);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(3));
        },
        // NOTE: We use filters to the preloaded data before rendering the values:
        setSelectedDateOfBirth() {
            return this.$filters.formatDate(this.generalInformation.dateOfBirth, 'yyyy-MM-dd');
        },
        // Applying filters before rendering values (E.g: 0.15 => 15.00%):
        annualRateToPercent() {
            return this.$filters.formatPercent(this.quotation.annualRate);
        },
        openingCommissionToPercent() {
            return this.$filters.formatPercent(this.quotation.openingCommission);
        },
        // Calculate totals:
        getMonthlyIncomesTotal() {
            this.monthlyIncomes.total = this.monthlyIncomes.salaries + this.monthlyIncomes.rentalIncome +  this.monthlyIncomes.dividends +  this.monthlyIncomes.interests +  this.monthlyIncomes.other;
            return  this.monthlyIncomes.total;
        },
        getMonthlyExpensesTotal() {
            this.monthlyExpenses.total = this.monthlyExpenses.housing + this.monthlyExpenses.maintenance + this.monthlyExpenses.tuition+ this.monthlyExpenses.entertainment+ this.monthlyExpenses.other;
            return this.monthlyExpenses.total;
        },
        getEconomicDependentsTotal() {
            this.economicDependents.total = this.economicDependents.spouse + this.economicDependents.children + this.economicDependents.parents + this.economicDependents.siblings + this.economicDependents.other;
            return this.economicDependents.total;
        },
        // Custom validators:
        minDate() {
            const disbursmentDate = new Date(this.quotation.disbursementDate);
            return yup.date().required('El campo es requerido').max(disbursmentDate, 'La fecha de la solicitud no debe ser mayor a la fecha de disposición del crédito');
        },
    },
    methods: {
        ...mapActions(useIndividualProspectsStore, ['fetchIndividualProspectusById']),
        ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchQuestionsByGroupNumber', 'createIndividualLoanApplication']),
        onSubmit() {
            this.individualLoanApplicationData.loanApplication = this.loanApplication;
            this.individualLoanApplicationData.branchOffice.id = this.quotation.branchOfficeId;
            this.individualLoanApplicationData.monthlyIncomes = this.monthlyIncomes;
            this.individualLoanApplicationData.monthlyExpenses = this.monthlyExpenses;
            this.individualLoanApplicationData.economicDependents = this.economicDependents;
            this.individualLoanApplicationData.directDebit = this.directDebit;
            this.individualLoanApplicationData.financialInformation = this.financialInformation;
            this.individualLoanApplicationData.applicantLoanReferences = this.applicantLoanReferences;
            this.individualLoanApplicationData.productDetails = this.productDetails;
            this.individualLoanApplicationData.interview = this.interview;
            this.individualLoanApplicationData.answers = this.answers;

            this.createIndividualLoanApplication(this.individualLoanApplicationData);
            this.$refs.individual.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        branchOfficeOptions(data) {
            return helper.mapOptions(data);
        },
        setNewAnswer(index, selectedOptionValue) {
            this.answers[index].dataValue = selectedOptionValue.toString();
        },
        selectedBooleanToString(property) {
            return property ? "1" : "0";
        },
        setNumberValue(value) {
            switch(value) {
                case false:
                    return 1;
                case true:
                    return 2;
                default:
                    return 0;
            }
        },
    },
    watch: {
        getActiveBranchOffices: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.activeBranchOfficesOptions = this.branchOfficeOptions(newValue);
                }
            }
        },
        'getSelectedProspectus': {
            immediate: true,
            handler(newValue) {
                if (newValue.prospectusId !== 0) {
                    this.prospectus = newValue;
                    this.generalInformation = newValue.generalInformation;
                    this.homeAddress = newValue.homeAddress;
                    this.fiscalAddress = newValue.fiscalAddress;
                    this.selectedBranchOfficeId = newValue.branchOfficeId;
                    this.selectedActivity = this.setNumberValue(this.generalInformation.isBusinessActivity);
                    this.selectedHasActivity = this.setNumberValue(this.generalInformation.rfcWithoutBusinessActivity);
                    this.selectedMortgagedHome = this.setNumberValue(this.homeAddress.mortgagedHome);

                    this.loanApplication.prospectusId = this.prospectusId;
                    this.loanApplication.quotationId = this.quotation.id;
                }
            }
        },
    },
    created() {
        // Call the prospectus by id
        this.fetchIndividualProspectusById(this.prospectusId);
    },
    mounted() {
    },
}
</script>